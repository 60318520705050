export const createPluginWithSetLimits = (
	min,
	max,
	shouldDisplay,
	maxColor = "#FF3F3F",
	minColor = "#FF3F3F"
) => ({
	id: "overlimitPointer",
	afterDatasetsDraw: (chart) => {
		const {
			ctx,
			data,
			chartArea: { top, bottom },
			scales: { x },
		} = chart;
		ctx.save();
		const drawArrow = (color, index, location, height, width) => {
			ctx.beginPath();
			ctx.fillStyle = color;
			if (location === "top") {
				ctx.moveTo(x.getPixelForValue(index) - width, top + height + 2);
				ctx.lineTo(x.getPixelForValue(index), top + 2);
				ctx.lineTo(x.getPixelForValue(index) + width, top + height + 2);
			} else {
				ctx.moveTo(
					x.getPixelForValue(index) - width,
					bottom - height - 2
				);
				ctx.lineTo(x.getPixelForValue(index), bottom - 2);
				ctx.lineTo(
					x.getPixelForValue(index) + width,
					bottom - height - 2
				);
			}
			ctx.closePath();
			ctx.fill();
		};
		data.datasets[0].data.forEach((dataPoint, index) => {
			if (shouldDisplay[index])
				if (dataPoint?.every((item) => item < min)) {
					drawArrow(minColor, index, "bottom", 5, 7);
				} else if (dataPoint?.every((item) => item > max)) {
					drawArrow(maxColor, index, "top", 5, 7);
				}
		});
	},
});

import { Box, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LayoutItem } from "../layout/LayoutItem";
import DefaultSnackbar from "../profile/Snackbar";
import { getFormList } from "../../store/colorSlice";

const LayoutItems = styled.div`
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	padding: 2rem 0;
`;

const LayoutList = () => {
	const { formList } = useSelector((state) => state.color);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSnack, setShowSnack] = useState({
		show: false,
		success: false,
		msg: "msg",
	});

	useEffect(() => {
		dispatch(getFormList());
	}, []);

	const addLayoutHandler = () => {
		navigate("/settings/form/add");
	};

	const layoutOptions = useMemo(() => {
		return [...formList]
			?.sort((a, b) => a.name.localeCompare(b.name))
			?.map((product) => (
				<LayoutItem key={product.uuid} product={product} />
			));
	}, [formList]);

	return (
		<Box sx={{ paddingTop: "32px", paddingBottom: "40px" }}>
			<Button
				sx={{
					color: "#4357A1",
					fontFamily: "Roboto",
					fontWeight: "600",
					fontSize: "22px",
					borderRadius: "4px",
					padding: "2px 40px",
				}}
				onClick={addLayoutHandler}
			>
				{t("qpbc.entities.layout.create")}
			</Button>
			<LayoutItems>{layoutOptions}</LayoutItems>
			<DefaultSnackbar
				open={showSnack.show}
				setOpen={setShowSnack}
				error={showSnack.success}
				msg={showSnack.msg}
			/>
		</Box>
	);
};

export default LayoutList;

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import OldPass from "./OldPass";
import NewPass from "./NewPass";
import ConfirmPass from "./ConfirmPass";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "../../store/userSlice";
import PassSnackbar from "./PassSnackbar";
import { useTranslation } from "react-i18next";

const ChangePass = () => {
	const { passError } = useSelector((state) => state.user);
	const [passData, setPassData] = useState({
		old: "",
		newPass: "",
		confirm: "",
	});
	const [openSnack, setOpenSnack] = useState(false);
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(
			changeUserPassword({
				current_password: passData.old,
				new_password: passData.newPass,
				confirm_new_password: passData.confirm,
			})
		);
		const newData = { old: "", newPass: "", confirm: "" };
		setPassData(newData);
		setOpenSnack(true);
	};

	return (
		<Box
			width="30vw"
			height="34vh"
			display={"flex"}
			alignItems="center"
			flexDirection="column"
			sx={{
				backgroundColor: "white",
				borderRadius: "6px",
				boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
			}}
		>
			<Typography sx={{ fontSize: "22px", fontWeight: "600", m: "10px" }}>
				{t("profile.pass_label")}
			</Typography>
			<OldPass
				old={passData.old}
				setPassData={setPassData}
				passData={passData}
			/>
			<NewPass
				newPass={passData.newPass}
				setPassData={setPassData}
				passData={passData}
			/>
			<ConfirmPass
				confirm={passData.confirm}
				setPassData={setPassData}
				passData={passData}
			/>
			<Typography m="1rem" fontSize={"14px"}>
				{t("profile.pass_desc")}
			</Typography>
			<Button onClick={handleClick}>{t("profile.apply")}</Button>
			<PassSnackbar
				open={openSnack}
				setOpen={setOpenSnack}
				error={passError && passError}
			/>
		</Box>
	);
};

export default ChangePass;

import Tooltip from "./UI/Tooltip";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CircleIndicator } from "./CircleIndicator";

const CircleWrapper = styled.div`
	display: flex;
	width: calc(100% / 30);
	justify-content: center;
	border: 1px solid transparent;
	border-radius: 250px;
	z-index: 1;
	cursor: pointer;
	&:hover {
		visibility: visible;
		border: 1px solid gray;
	}
`;

export const DefectCircle = ({
	color,
	pallet_id,
	leftCount,
	onClick,
	sizing,
}) => {
	const { equipment } = useSelector((state) => state.settings);

	const [clicked, setClicked] = useState(false);
	const handleClickOutside = () => {
		setClicked(false);
	};

	const ref = useOnClickOutside(handleClickOutside);

	const clickHandler = (e) => {
		e.preventDefault();
		setClicked(!clicked);
		onClick();
	};

	return (
		<CircleWrapper
			ref={ref}
			style={{
				border: clicked ? "1px solid blue" : "",
				height: equipment.weigher ? "100%" : "50%",
				marginLeft: "0px",
			}}
			onClick={clickHandler}
		>
			<Tooltip
				content={
					pallet_id
						? pallet_id.slice(-8).split(".").join(":")
						: "Нет данных"
				}
			>
				<CircleIndicator color={color} />
			</Tooltip>
		</CircleWrapper>
	);
};

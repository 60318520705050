import styled from "styled-components";
import { Table } from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getNoTokenUsers } from "../store/userSlice";
import { useDataTableFormatter } from "../hooks/useDataTableFormatter";
import { UserDataManagement } from "./UserDataManagement";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DialogWrapper } from "./DialogWrapper";
import { AddNewUserDialog } from "./AddNewUserDialog";
import { EditUserDialog } from "./EditUserDialog";
import DefaultSnackbar from "./profile/Snackbar";

const Content = styled.div`
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Header = styled.div`
	display: flex;
	gap: 4rem;
	align-items: center;
`;

const TableWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
`;

const HeaderText = styled.p`
	font-weight: bold;
	font-size: 22px;
`;

export const UserManagementPanel = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [snackbarState, setSnackbarState] = useState({
		show: false,
		success: false,
		msg: "",
	});

	const dispatch = useDispatch();
	const { customContentFormatter, translationFormatter } =
		useDataTableFormatter();
	const { t } = useTranslation();

	const { noTokenUsers } = useSelector((state) => state.user);

	const columns = [
		{
			name: "name",
		},
		{
			name: "login",
		},
		{
			name: "role",
			formatter: translationFormatter,
		},
		{
			name: "management",
			customWidth: "240px",
			formatter: customContentFormatter(UserDataManagement, {
				openDialog: (user) => {
					setIsEdit(true);
					setSelectedUser(user);
					setIsDialogOpen(true);
				},
			}),
		},
	];

	const handleDialogClose = () => {
		setIsDialogOpen(false);
	};

	const handleDialogOpen = () => {
		setIsEdit(false);
		setIsDialogOpen(true);
	};

	useEffect(() => {
		dispatch(getNoTokenUsers());
	}, []);

	return (
		<>
			<Content>
				<Header>
					<HeaderText>
						{t("settings.users.userManagement")}
					</HeaderText>
					<Button
						onClick={handleDialogOpen}
						sx={{ fontSize: "17px" }}
					>
						{t("settings.users.newUser")}
					</Button>
				</Header>
				<TableWrapper>
					<Table columns={columns} data={noTokenUsers} />
				</TableWrapper>
				<DialogWrapper open={isDialogOpen} onClose={handleDialogClose}>
					<>
						{" "}
						{isEdit ? (
							<EditUserDialog
								setSnackbarState={setSnackbarState}
								user={selectedUser}
								closeDialog={handleDialogClose}
							/>
						) : (
							<AddNewUserDialog
								setSnackbarState={setSnackbarState}
								closeDialog={handleDialogClose}
							/>
						)}{" "}
					</>
				</DialogWrapper>
			</Content>
			<DefaultSnackbar
				open={snackbarState.show}
				setOpen={setSnackbarState}
				error={snackbarState.success}
				msg={snackbarState.msg}
			/>
		</>
	);
};

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useDateFormatter } from "./useDateFormatter";

const FullWidthContentWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const CellContentWrapper = styled.div`
	display: flex;
	gap: 6px;
`;

const MeasureUnit = styled.p`
	color: #a4a4a4;
	white-space: nowrap;
`;

export const useDataTableFormatter = () => {
	const { t } = useTranslation();
	const formatDate = useDateFormatter();

	const { system } = useSelector((state) => state.settings);

	const areaFormatter = (value) => (
		<CellContentWrapper>
			{value ? value.toFixed(2) : 0}
			<MeasureUnit>{t("measureUnits.m2")}</MeasureUnit>
		</CellContentWrapper>
	);

	const piecesFormatter = (value) => (
		<CellContentWrapper>
			{value} <MeasureUnit>{t("measureUnits.pcs")}</MeasureUnit>
		</CellContentWrapper>
	);

	const timeFormatter = (value) => value?.slice(11, 19);

	const differenceFormatter = (differenceField) => (value, entry) =>
		(
			<CellContentWrapper>
				{`${value - entry[differenceField]}`}
			</CellContentWrapper>
		);
	const dateFormatter = (value) => {
		return formatDate(value, true);
	};

	const overallAreaFormatter = (value, report) => (
		<CellContentWrapper>
			{report.unit_type === "m2" ? (
				<>
					{value - Number(value.toFixed(0))
						? value.toFixed(2)
						: value}
					<MeasureUnit>{t("measureUnits.m2")}</MeasureUnit>
				</>
			) : (
				<>
					{value} <MeasureUnit>{t("measureUnits.pcs")}</MeasureUnit>
				</>
			)}
		</CellContentWrapper>
	);

	const someOfTotalFormatter = (totalField) => (value, entry) =>
		(
			<CellContentWrapper>
				{`${value}/${entry[totalField]}`}
			</CellContentWrapper>
		);

	const complexPercentFormatter = (totalField) => (value, entry) =>
		(
			<CellContentWrapper>
				{((value / entry[totalField]) * 100).toFixed(1)}
				<MeasureUnit>%</MeasureUnit>
			</CellContentWrapper>
		);

	const percentFormatter = (value) => (
		<CellContentWrapper>
			{(value * 100).toFixed(1)}
			<MeasureUnit>%</MeasureUnit>
		</CellContentWrapper>
	);

	const multiPercentFormatter = (fields) => (_, entry) =>
		(
			<FullWidthContentWrapper>
				{fields.map((field, index) => (
					<>
						{index ? "-" : ""}
						<CellContentWrapper key={field}>
							{(entry[field] * 100).toFixed(1)}
							<MeasureUnit>%</MeasureUnit>
						</CellContentWrapper>
					</>
				))}
			</FullWidthContentWrapper>
		);

	const secondsFormatter = (value) => value.toFixed(1);

	const heightFormatter = (value) => (
		<CellContentWrapper>
			{value.toFixed(1)}
			<MeasureUnit>{t("measureUnits.mm")}</MeasureUnit>
		</CellContentWrapper>
	);

	const mmFormatter = (value) => (
		<CellContentWrapper>
			{Number(value.toFixed(2))}
			<MeasureUnit>{t("measureUnits.mm")}</MeasureUnit>
		</CellContentWrapper>
	);

	const densityFormatter = (value) => {
		const isDm = system?.density_measure_unit === "kg_dm3";
		return value ? (
			<CellContentWrapper>
				{isDm ? (value / 1000).toFixed(2) : value.toFixed(1)}
				<MeasureUnit>
					{t(isDm ? "measureUnits.kg/dm" : "measureUnits.kg/m")}
				</MeasureUnit>
			</CellContentWrapper>
		) : (
			""
		);
	};

	const weightFormatter = (value) => (
		<CellContentWrapper>
			{value.toFixed(1)}
			<MeasureUnit>{t("measureUnits.kg")}</MeasureUnit>
		</CellContentWrapper>
	);

	const customContentFormatter =
		(CustomContent, props) => (value, entry, data) =>
			(
				<CellContentWrapper>
					<CustomContent
						value={value}
						{...(props ?? {})}
						{...entry}
						data={data}
					/>
				</CellContentWrapper>
			);

	const translationFormatter = (value) => t(value);

	return {
		areaFormatter,
		timeFormatter,
		dateFormatter,
		piecesFormatter,
		overallAreaFormatter,
		complexPercentFormatter,
		percentFormatter,
		multiPercentFormatter,
		weightFormatter,
		secondsFormatter,
		mmFormatter,
		differenceFormatter,
		translationFormatter,
		heightFormatter,
		customContentFormatter,
		densityFormatter,
		someOfTotalFormatter,
	};
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

import { ReactComponent as OpPanelIcon } from "../../icons/oper_screen.svg";
import { ReactComponent as ReportIcon } from "../../icons/report.svg";
import { ReactComponent as DrySideIcon } from "../../icons/sort_screen.svg";
import { ReactComponent as SettingsIcon } from "../../icons/tool_1.svg";
import { ReactComponent as InstructionsIcon } from "../../icons/info.svg";
import { ReactComponent as LogOutIcon } from "../../icons/log_out.svg";
import { ReactComponent as DeprecationIcon } from "../../icons/deprecation.svg";

import styled from "styled-components";
import { authLogout } from "../../store/authSlice";
import { switchLight } from "../../store/rfidSlice";
import {
	closeUserSession,
	getCurrentUserSession,
	getUser,
} from "../../store/userSlice";
import { UserMenu } from "./UserMenu";
import { NavMenuLink } from "./NavMenuLink";
import { Logo } from "./Logo";
import { SideBarButton } from "./SideBarButton";
import { SupportButton } from "./SupportButton";
import { TooltipWrapper } from "../TooltipWrapper";
import { getTokenChat } from "../../store/supportSlice";
import { SupportWindow } from "./SupportWindow";

const Wrapper = styled.div`
	padding-left: 60px;
	min-height: 100vh;
	background: #DBE2F1;
	position: relative;
	${(props) => (props.disableScroll ? "overflow: hidden; height: 100vh" : "")}
`;

const Link = styled.div`
	padding: 0 5px;
	color: #5A76A3;
	fill: #5A76A3;
	text-decoration: none;
	border-radius: 4px;
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;

	:hover {
		background-color: #ebeff5;
	}

	:active {
		color: #4357A0;
		fill: #4357A0;
		background-color: #dbe2f1;
	}
`;

const LinkText = styled.p`
	color: inherit;
	white-space: nowrap;
	padding-right: 10px;
`;

const SideBar = styled.div`
	z-index: 1001;
	letter-spacing: 0.04em;
	position: fixed;
	min-width: ${(props) => (props.isFull ? "260px" : "0")};
	max-width: 320px;
	top: 0;
	font-size: 16px;
	left: 0;
	height: 100vh;
	background: #F7F9FF;
	display: flex;
	padding-top: 10px;
	padding-bottom: 40px;
	gap: 10px;
	flex-direction: column;
	${(props) => (props.isFull ? "box-shadow: 2px 0 6px #393D4C40" : "")};
	transition-duration: 50ms;
`;

const Shading = styled.div`
	z-index: 1000;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: #45507633;
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LogoWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	color: #4357A0;
`;

const Divider = styled.div`
	width: 100%;
	height: 2px;
	border-radius: 2px;
	background: #DBE2F1;
`;

const NavigationMenu = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 5px;
	gap: 1rem;
`;

const UserSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0 5px;
`;

const MenuWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

const Layout = () => {
	const { users, currentUserSession } = useSelector((state) => state.user);
	const { token } = useSelector((state) => state.support);
	const { apiToken } = useSelector((state) => state.auth);
	const { switchState } = useSelector((state) => state.rfid);
	const [isFull, setIsFull] = useState(false);
	const [isSupportOpen, setIsSupportOpen] = useState(false);
	const { t } = useTranslation();

	const [selectUser, setSelectUser] = useState();

	const dispatch = useDispatch();

	const links = [
		{ link: "/panel", label: t("layoutScreen"), icon: <OpPanelIcon /> },
		{ link: "/report", label: t("layoutProdReport"), icon: <ReportIcon /> },
		{ link: "/rfid", label: t("layoutDry"), icon: <DrySideIcon /> },
		{
			link: "/settings",
			label: t("layoutSettings"),
			icon: <SettingsIcon />,
			subLinks: [
				{ link: "/settings/color", label: t("settings.title.color") },
				{ link: "/settings/form", label: t("settings.title.form") },
				{
					link: "/settings/config",
					label: t("settings.title.setting"),
				},
				...(users?.role === "operator"
					? []
					: [
							{
								link: "/settings/sense",
								label: t("settings.title.defect"),
							},
							{
								link: "/settings/users",
								label: t("settings.title.users"),
							},
					  ]),
			],
		},
		{
			link: "/deprecation",
			label: t("layoutDeprecation"),
			icon: <DeprecationIcon />,
		},
		{
			link: "/notion",
			label: t("layoutInstr"),
			icon: <InstructionsIcon />,
		},
	];

	useEffect(() => {
		if (!token) {
			dispatch(getTokenChat());
		}
	}, [token, dispatch]);

	useEffect(() => {
		dispatch(getUser());
		dispatch(getCurrentUserSession());
		setSelectUser(currentUserSession?.username);
	}, [currentUserSession?.username, selectUser]);

	useEffect(() => {
		if (switchState) {
			setTimeout(() => dispatch(switchLight(false)), 1500);
		}
	}, [switchState]);

	const outHandler = (e) => {
		setSelectUser(null);
		dispatch(closeUserSession({ username: selectUser }));
		dispatch(authLogout());
	};

	const closeOnClick = () => {
		setIsFull(false);
		setIsSupportOpen(false);
	};

	return apiToken ? (
		<Wrapper disableScroll={isFull || isSupportOpen}>
			<SideBar isFull={isFull}>
				<UserSection>
					<HeaderWrapper>
						<LogoWrapper>
							<Logo />
							{isFull && <p>QuatroPBC</p>}
						</LogoWrapper>
						<TooltipWrapper
							text={isFull ? t("menuClose") : t("menuOpen")}
						>
							<SideBarButton
								onClick={() => setIsFull((prev) => !prev)}
								isFull={isFull}
							/>
						</TooltipWrapper>
					</HeaderWrapper>
					<TooltipWrapper isOff={isFull} text={users?.name ?? ""}>
						<UserMenu
							closeOnClick={closeOnClick}
							userName={users?.name ?? ""}
							isFull={isFull}
						/>
					</TooltipWrapper>
				</UserSection>
				<Divider />
				<MenuWrapper>
					<NavigationMenu>
						{links.map((link) => (
							<NavMenuLink
								{...link}
								isFull={isFull}
								closeOnClick={closeOnClick}
							/>
						))}
					</NavigationMenu>
					<NavigationMenu>
						<SupportButton
							openSupport={() => {
								setIsSupportOpen((prev) => !prev);
								setIsFull(false);
							}}
							isFull={isFull}
							isOpen={isSupportOpen}
						/>
						<Link onClick={outHandler}>
							<LogOutIcon />
							{isFull && <LinkText>{t("layoutExit")}</LinkText>}
						</Link>
					</NavigationMenu>
				</MenuWrapper>
			</SideBar>
			{isFull && <Shading onClick={() => setIsFull(false)} />}
			{isSupportOpen && (
				<SupportWindow
					token={token}
					closeSupport={() => setIsSupportOpen((prev) => !prev)}
				/>
			)}
			<Outlet />
		</Wrapper>
	) : (
		<Navigate replace to={"/login"} />
	);
};

export default Layout;

import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getDefaultColorSettings,
	getSpecialSettings,
} from "../../store/defectsInspectionSlice";
import styled from "styled-components";
import { SpecialDefectInspectionSettings } from "./SpecialDefectInspectionSettings";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-bottom: 3rem;
	padding-top: 3rem;
`;

const ColorParams = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getSpecialSettings());
	}, []);

	return (
		<Content>
			<Button
				sx={{ width: "fit-content", marginLeft: "auto" }}
				onClick={() => navigate("test")}
			>
				{t("defectInspection.checkInspection")}
			</Button>
			<SpecialDefectInspectionSettings />
		</Content>
	);
};

export default ColorParams;

import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { ProductImagePreview } from "./ProductImagePreview";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const LayoutItem = ({ product }) => {
	return (
		<Container>
			<ProductImagePreview product_id={product.uuid} />
			<Link
				to={product.uuid}
				style={{
					width: "280px",
					textAlign: "center",
					fontSize: "24px",
				}}
			>
				{product.name}
			</Link>
		</Container>
	);
};

import { Button } from "@mui/material";
import ComboBox from "./ComboBox";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BigCircleLoader from "./UI/BigCircleLoader";
import {
	requestTempBatches,
	statusReset,
	submitBatchEdits,
} from "../store/newReportSlice";
import { useTranslation } from "react-i18next";
import { getColorList, getFormList } from "../store/colorSlice";
import { getNoTokenUsers } from "../store/userSlice";
import { LocalizedDateTimePicker } from "./DateTimePicker";
import dayjs from "dayjs";
import DefaultSnackbar from "./profile/Snackbar";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	width: 500px;
	overflow: visible;
`;

const Header = styled.p`
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-end;
	flex: 1;
`;

const Label = styled.p`
	width: 30%;
`;

export const BatchParamsChangeForm = ({ closeForm, batch, filters }) => {
	const { colorList, formList } = useSelector((state) => state.color);
	const { noTokenUsers } = useSelector((state) => state.user);
	const { requestedDates, propertiesStatus, tempBatches } = useSelector(
		(state) => state.newReport
	);
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		show: false,
		success: false,
		msg: "",
	});

	const { t } = useTranslation();

	const [form, setForm] = useState({
		uuid: batch.product_id,
		name: batch.product_name,
	});
	const [color, setColor] = useState({
		uuid: batch.color_id,
		name: batch.color_name,
	});
	const [user, setUser] = useState({
		uuid: batch.user?.uuid ?? null,
		name: batch.user?.name ?? "",
	});
	const [begin, setBegin] = useState(dayjs(batch?.bdt));
	const [end, setEnd] = useState(dayjs(batch?.edt));

	const currentBatchIndex = useMemo(() => {
		let ind = tempBatches?.findIndex(
			(tempBatch) => tempBatch.batch_id === batch.batch_id
		);
		return ind;
	}, [tempBatches]);

	useEffect(() => {
		if (propertiesStatus === "fulfilled") {
			dispatch(statusReset());
			closeForm();
		} else if (propertiesStatus === "rejected") {
			setSnackbarState({
				show: true,
				success: false,
				msg: t("newReport.borderError"),
			});
			dispatch(statusReset());
		}
	}, [propertiesStatus]);

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		Promise.allSettled([
			dispatch(getFormList()),
			dispatch(getColorList()),
			dispatch(getNoTokenUsers()),
		]).then(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		dispatch(
			requestTempBatches({
				currentBatch: batch,
			})
		);
	}, [batch]);

	const areDatesValid = useMemo(() => {
		const isBeginRight = () => {
			return (
				+new Date(
					dayjs(
						tempBatches.at(
							currentBatchIndex === 0 ? 0 : currentBatchIndex - 1
						)?.bdt
					).format()
				) <= +new Date(begin.format()) &&
				+new Date(dayjs(batch?.edt).format()) >=
					+new Date(begin.format())
			);
		};

		const isEndRight = () => {
			return (
				+new Date(dayjs(batch?.bdt).format()) <=
					+new Date(end.format()) &&
				+new Date(
					dayjs(
						tempBatches.at(
							currentBatchIndex === tempBatches.length - 1
								? tempBatches.length - 1
								: currentBatchIndex + 1
						)?.edt
					).format()
				) >= +new Date(end.format())
			);
		};

		return isBeginRight() && isEndRight();
	}, [tempBatches, currentBatchIndex, begin, end]);

	const handleSubmit = async () => {
		if (+new Date(dayjs(batch.bdt).format()) > +new Date(begin.format())) {
			await dispatch(
				submitBatchEdits({
					batchId: tempBatches[currentBatchIndex - 1].batch_id,
					end_dt: new Date(begin.format()).toISOString(),
				})
			);
			await dispatch(
				submitBatchEdits({
					batchId: batch.batch_id,
					begin_dt: new Date(begin.format()).toISOString(),
				})
			);
		} else if (
			+new Date(dayjs(batch.bdt).format()) < +new Date(begin.format())
		) {
			await dispatch(
				submitBatchEdits({
					batchId: batch.batch_id,
					begin_dt: new Date(begin.format()).toISOString(),
				})
			);
			await dispatch(
				submitBatchEdits({
					batchId: tempBatches[currentBatchIndex - 1].batch_id,
					end_dt: new Date(begin.format()).toISOString(),
				})
			);
		}
		if (+new Date(dayjs(batch.edt).format()) > +new Date(end.format())) {
			await dispatch(
				submitBatchEdits({
					batchId: batch.batch_id,
					end_dt: new Date(end.format()).toISOString(),
				})
			);
			await dispatch(
				submitBatchEdits({
					batchId: tempBatches[currentBatchIndex + 1].batch_id,
					begin_dt: new Date(end.format()).toISOString(),
				})
			);
		} else if (
			+new Date(dayjs(batch.edt).format()) < +new Date(end.format())
		) {
			await dispatch(
				submitBatchEdits({
					batchId: tempBatches[currentBatchIndex + 1].batch_id,
					begin_dt: new Date(end.format()).toISOString(),
				})
			);
			await dispatch(
				submitBatchEdits({
					batchId: batch.batch_id,
					end_dt: new Date(end.format()).toISOString(),
				})
			);
		}
		dispatch(
			submitBatchEdits({
				batchId: batch.batch_id,
				product_id: form.uuid,
				color_id: color.uuid,
				user_id: user.uuid,
				filters,
				requestedDates: requestedDates,
			})
		);
	};

	return (
		<Content>
			<Header>{t("newReport.editBatchProperties")}</Header>
			{!isLoading ? (
				<>
					<Wrapper>
						<Label>{t("newReport.form")}:</Label>
						<ComboBox
							options={formList}
							setValue={setForm}
							value={form}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.color")}:</Label>
						<ComboBox
							options={colorList}
							setValue={setColor}
							value={color}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.operator")}:</Label>
						<ComboBox
							options={noTokenUsers
								.filter((user) => user.role === "operator")
								.map((user) => ({
									name: user.name,
									uuid: user.id,
								}))}
							setValue={setUser}
							value={user}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.begin")}:</Label>
						<LocalizedDateTimePicker
							sx={{ width: "280px" }}
							setValue={setBegin}
							value={begin}
							disabled={currentBatchIndex === 0}
							minDateTime={dayjs(
								tempBatches.at(currentBatchIndex - 1)?.bdt
							)}
							maxDateTime={dayjs(batch?.edt)}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.end")}:</Label>
						<LocalizedDateTimePicker
							sx={{ width: "280px" }}
							setValue={setEnd}
							value={end}
							disabled={
								currentBatchIndex === tempBatches.length - 1
							}
							minDateTime={dayjs(batch?.bdt)}
							maxDateTime={dayjs(
								tempBatches.at(currentBatchIndex + 1)?.edt
							)}
						/>
					</Wrapper>
					<ButtonsWrapper>
						<Wrapper>
							<Button sx={{ color: "red" }} onClick={closeForm}>
								{t("newReport.cancel")}
							</Button>
							<Button
								onClick={handleSubmit}
								disabled={!areDatesValid}
							>
								{t("newReport.apply")}
							</Button>
						</Wrapper>
					</ButtonsWrapper>
					<DefaultSnackbar
						open={snackbarState.show}
						setOpen={setSnackbarState}
						error={snackbarState.success}
						msg={snackbarState.msg}
					/>
				</>
			) : (
				<BigCircleLoader />
			)}
		</Content>
	);
};

import styled from "styled-components";
import { Table } from "./Table";
import { LocalizedDatePicker } from "./DatePicker";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "react-chartjs-2";
import CircleLoader from "./UI/CircleLoader";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getPalletList } from "../store/deprecationSlice";

const Content = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
`;

const DatePickerBlock = styled.div`
	display: flex;
	gap: 1rem;
`;

const DatePickerWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const StatsBlock = styled.div`
	display: flex;
	gap: 3rem;
`;

const TableWrapper = styled.div`
	flex: 1;
	border-radius: 6px;
	overflow: hidden;
`;

const ChartWrapper = styled.div`
	flex: 1;
	background: #fff;
	border-radius: 6px;
	padding: 4px 8px;
`;

const Header = styled.p`
	font-size: 1.5rem;
`;

export const PalletDeprecationStat = () => {
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);

	const { palletList, palletListStatus } = useSelector(
		(state) => state.deprecation
	);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const chartData = useMemo(() => {
		const data = [];
		const labels = [];
		const breakPoint = Math.ceil(
			Math.max(...palletList.map((dataPoint) => dataPoint.total)) / 20
		);
		let limit = breakPoint;

		const areAnyInRange = (dataPoint) =>
			dataPoint.total >= limit - breakPoint;

		const getValuesInRange = (dataPoint) =>
			dataPoint.total > limit - breakPoint && dataPoint.total <= limit;

		while (palletList.some(areAnyInRange)) {
			labels.push(String(limit));
			data.push(palletList.filter(getValuesInRange).length);
			limit += breakPoint;
		}
		return {
			labels,
			datasets: [
				{
					label: t("palletNumber"),
					data,
					backgroundColor: "rgba(0, 106, 211, 0.7)",
				},
			],
		};
	}, [palletList, t]);

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				x: {
					title: { text: t("cycles"), display: true },
				},
				y: {
					title: { text: t("palletNumber"), display: true },
				},
			},
			plugins: {
				tooltip: {
					callbacks: {
						title: (context) => {
							return `${t("rounds")}: ${context.at(0).label}`;
						},
					},
				},
			},
		}),
		[]
	);

	const columns = [
		{
			name: "tag_id",
			customWidth: "350px",
		},
		{
			name: "total",
			customName: "rounds",
		},
	];

	const handleSearch = useCallback(() => {
		dispatch(
			getPalletList({
				from: new Date(from.format()).toISOString(),
				to: new Date(to.format()).toISOString(),
			})
		);
	}, [dispatch, from, to]);

	const chartObject = useMemo(
		() => (
			<Chart
				data={chartData}
				options={options}
				type={"bar"}
				redraw={true}
			/>
		),
		[chartData, options]
	);

	return (
		<Content>
			<Header>{t("deprecation.palletHeading")}</Header>
			<DatePickerBlock>
				<DatePickerWrapper>
					<p>{t("from")}:</p>
					<LocalizedDatePicker
						variant="standard"
						value={from}
						setValue={setFrom}
					/>
				</DatePickerWrapper>
				<DatePickerWrapper>
					<p>{t("to")}:</p>
					<LocalizedDatePicker
						variant="standard"
						value={to}
						setValue={setTo}
					/>
				</DatePickerWrapper>
				<Button
					disabled={palletListStatus === "pending"}
					sx={{ display: "flex", gap: "0.5rem" }}
					onClick={handleSearch}
				>
					{t("deprecation.search")}
					{palletListStatus === "pending" && <CircleLoader />}
				</Button>
			</DatePickerBlock>
			<StatsBlock>
				<TableWrapper>
					<Table
						data={palletList}
						columns={columns}
						dataIdField="tag_id"
						height="480px"
					/>
				</TableWrapper>
				<ChartWrapper>{chartObject}</ChartWrapper>
			</StatsBlock>
		</Content>
	);
};

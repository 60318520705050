import {
	Box,
	Button,
	TextField,
	Typography,
	MenuItem,
	InputAdornment,
} from "@mui/material";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PicturesSelectionForm } from "./PicturesSelectionForm";
import DefaultSnackbar from "../profile/Snackbar";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addLayout, addExampleByLayoutId } from "./LayoutFetch";
import { getProducts } from "../../store/productSlice";

const InputPair = styled.div`
	display: flex;
	gap: 1rem;
	width: 640px;
`;

const AddLayout = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [selectedExamples, setSelectedExamples] = useState([]);

	const dispatch = useDispatch();

	const [product, setProduct] = useState({
		name: "",
		entire_surface: "",
		unit_type: "m2",
		stone_border_width: 20,
		height: "",
		display_name: "",
	});

	const [params, setParams] = useState({
		nominal_volume: "",
		nominal_height: "",
	});

	const [settings, setSettings] = useState({
		height_min_threshold: "",
		height_max_threshold: "",
		height_min_recomended: "",
		height_max_recomended: "",
		density_min_threshold: "",
		density_max_threshold: "",
		textureless_defect_threshold: 0,
	});

	const [showSnack, setShowSnack] = useState({
		show: false,
		success: false,
		msg: "msg",
	});

	const numberRegex = /^[0-9]*?$/;
	const numberWithFractionsRegex = /^([0-9]*[.])?[0-9]*?$/;

	function editLayoutUnit(event) {
		setProduct((prev) => ({ ...prev, unit_type: event.target.value }));
	}

	const textHandler = (field, handler) => (event) => {
		if (event.target.value.includes("/")) return;
		handler((prev) => ({
			...prev,
			[field]: event.target.value,
		}));
	};

	const numericHandler = (field, handler, numberRegex) => (event) => {
		if (numberRegex.test(event.target.value)) {
			handler((prev) => ({
				...prev,
				[field]:
					event.target.value.length > 1
						? event.target.value.replace(/^0+(?=\d)/, "")
						: event.target.value,
			}));
		}
	};

	function validate() {
		if (selectedExamples.length > 0 && selectedExamples.length < 3) {
			return [false, t("qpbc.entities.layout.too-small-examples-set")];
		}
		return [true, null];
	}

	const createLayout = () => {
		let [success, msg] = validate();
		if (!success) {
			setShowSnack({
				show: true,
				success: false,
				msg: msg,
			});
			return;
		}
		const needObject = {
			...product,
			settings,
			params: {
				nominal_height: params.nominal_height
					? params.nominal_height
					: null,
				nominal_volume: params.nominal_volume
					? params.nominal_volume
					: null,
			},
		};
		const createData = async () => {
			let res;
			res = await addLayout(needObject);
			try {
				await Promise.all(
					selectedExamples.map(async (elem) => {
						await addExampleByLayoutId(res.uuid, elem);
					})
				);
			} catch (error) {
				setShowSnack({
					show: true,
					success: false,
					msg: t("qpbc.entities.layout.error-append-examples"),
				});
				return;
			}
		};
		createData()
			.then(() => {
				dispatch(getProducts());
				setShowSnack({
					show: true,
					success: true,
					msg: t("create-layout-success"),
				});
				navigate("/settings/form");
			})
			.catch((error) => {
				switch (JSON.parse(error.message).status) {
					case "409": {
						setShowSnack({
							show: true,
							success: false,
							msg: t("qpbc.entities.layout.name-collision-error"),
						});
						break;
					}
					case "422": {
						setShowSnack({
							show: true,
							success: false,
							msg:
								`${t("qpbc.settings.fillInFields")}: ` +
								JSON.parse(error.message)
									.body.detail.map((field) =>
										t(`qpbc.settings.${field.loc.at(-1)}`)
									)
									.join(", "),
						});
						break;
					}
					default: {
						setShowSnack({
							show: true,
							success: false,
							msg: t("qpbc.entities.layout.error-create-failed"),
						});
						break;
					}
				}
			});
	};

	const appendExample = (pallet_id) => {
		setSelectedExamples([...selectedExamples, pallet_id]);
	};

	const removeExample = (pallet_id) => {
		let examples = selectedExamples.filter((pid) => pid !== pallet_id);
		setSelectedExamples(examples);
	};

	return (
		<Box sx={{ padding: "3rem 1rem" }}>
			<Typography
				sx={{
					fontSize: "22px",
					fontWeight: "600",
					paddingBottom: "1.5rem",
				}}
			>
				{t("qpbc.entities.layout.self")}
			</Typography>
			<Box
				display={"flex"}
				flexDirection={"column"}
				maxWidth={"fit-content"}
				gap={"2rem"}
			>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.entities.layout.name")}
						value={product.name}
						onChange={textHandler("name", setProduct)}
						InputLabelProps={{ shrink: true }}
					/>
				</InputPair>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.entire_surface")}
						value={product.entire_surface}
						onChange={numericHandler(
							"entire_surface",
							setProduct,
							numberWithFractionsRegex
						)}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.m2")}</p>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.height")}
						value={product.height}
						onChange={numericHandler(
							"height",
							setProduct,
							numberWithFractionsRegex
						)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
					/>
				</InputPair>
			</Box>
			<Box sx={{ paddingTop: "1rem" }}>
				<Typography sx={{ fontSize: "16px" }}>
					{t("qpbc.entities.layout.unit-type")}
				</Typography>
				<Select
					value={product.unit_type}
					autoWidth
					onChange={editLayoutUnit}
				>
					<MenuItem value={"m2"}>
						{t("qpbc.entities.layout.m2-unit-type")}
					</MenuItem>
					<MenuItem value={"pcs"}>
						{t("qpbc.entities.layout.pcs-unit-type")}
					</MenuItem>
				</Select>
			</Box>
			<PicturesSelectionForm
				header={t("qpbc.entities.layout.select-examples")}
				subHeader={t("qpbc.entities.layout.examplesHint")}
				datePickerLabel={t("qpbc.entities.layout.datePickerLabel")}
				appendExample={appendExample}
				removeExample={removeExample}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
				}}
			>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.nominal_height")}
						value={params.nominal_height}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"nominal_height",
							setParams,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.nominal_volume")}
						value={params.nominal_volume}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm3")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"nominal_volume",
							setParams,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				{t("qpbc.settings.self")}
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.height_min_threshold")}
						value={settings.height_min_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_min_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.height_max_threshold")}
						value={settings.height_max_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_max_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.height_min_recomended")}
						value={settings.height_min_recomended}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_min_recomended",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.height_max_recomended")}
						value={settings.height_max_recomended}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"height_max_recomended",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
				<InputPair>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.density_min_threshold")}
						value={settings.density_min_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.kg/m")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"density_min_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
					<TextField
						sx={{ flex: 1 }}
						label={t("qpbc.settings.density_max_threshold")}
						value={settings.density_max_threshold}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.kg/m")}</p>
								</InputAdornment>
							),
						}}
						InputLabelProps={{ shrink: true }}
						onChange={numericHandler(
							"density_max_threshold",
							setSettings,
							numberWithFractionsRegex
						)}
					/>
				</InputPair>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
				}}
			>
				<Button
					sx={{ marginTop: "4rem" }}
					disabled={
						product.name === null ||
						product.name === "" ||
						!(product.entire_surface > 0)
					}
					onClick={createLayout}
				>
					{t("qpbc.entities.layout.create")}
				</Button>
			</Box>
			<DefaultSnackbar
				open={showSnack.show}
				setOpen={setShowSnack}
				error={showSnack.success}
				msg={showSnack.msg}
			/>
		</Box>
	);
};

export default AddLayout;

import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

const Content = styled.div`
	height: 100vh;
	z-index: 999;
	width: calc(100vw - 60px);
	position: fixed;
	top: 0;
	left: 60px;
`;

const StyledIframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`;

const CloseButtonWrapper = styled.div`
	position: absolute;
	left: 1rem;
	top: 1rem;
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
`;

export const PointCloudView = ({ handleClose, pointCloud }) => {
	return (
		<Content>
			<CloseButtonWrapper>
				<p>{pointCloud?.fileName?.slice(0, 19)}</p>
				<IconButton
					sx={{ width: "fit-content" }}
					size="large"
					onClick={handleClose}
				>
					<CloseIcon fontSize="large" />
				</IconButton>
				<a href={pointCloud?.url} download={pointCloud?.fileName}>
					<IconButton sx={{ width: "fit-content" }} size="large">
						<DownloadIcon fontSize="large" />
					</IconButton>
				</a>
			</CloseButtonWrapper>
			<StyledIframe title="support" src={pointCloud?.url} />
		</Content>
	);
};

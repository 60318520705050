import { useSelector } from "react-redux";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";

export const useDateFormatter = () => {
	const { dateFormat } = useSelector((state) => state.settings);

	return (date, accountForTimezone) => {
		const config = {
			format: "en-GB",
			options: {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			},
		};
		switch (dateFormat) {
			case "DD/MM/YYYY": {
				config.format = "en-GB";
				break;
			}
			case "MM/DD/YYYY": {
				config.format = "en-US";
				break;
			}
			case "YYYY/MM/DD": {
				config.format = "zh-CH";
				break;
			}
			default: {
				config.format = "en-GB";
				break;
			}
		}
		return (
			accountForTimezone
				? setTimeZoneOffset(new Date(date), date?.slice(-6, -3))
				: new Date(date)
		).toLocaleDateString(config.format, config.options);
	};
};

import { useTranslation } from "react-i18next";
import { DataItemWrapper } from "./DataItemWrapper";
import { RepeatsVisualizer } from "./RepeatsVisualizer";
import styled from "styled-components";
import { DataItemInfo } from "./DataItemInfo";
import { CycleTimeInfo } from "./CycleTimeInfo";
import { WeightInfo } from "./WeightInfo";
import { useSelector } from "react-redux";
import { numberFormatter } from "../../helper/numberFormatter";

const Content = styled.div`
		display: flex;
		gap: 10px;
		width: 100%;
		flex-wrap: nowrap;
`;

const WrapWrapper = styled.span`
	white-space: normal;
`;

const NoWrapWrapper = styled.span`
	white-space: nowrap;
`;

export const DataList = ({ scheme }) => {
	const { system, equipment } = useSelector((state) => state.settings);
	const { t } = useTranslation();

	return (
		<Content>
			<DataItemWrapper
				maxWidth={equipment?.weigher ? "152px" : "180px"}
				divider
				label={t("panel.titles.tact")}
			>
				<CycleTimeInfo
					palletId={scheme?.chart?.history?.at(0)?.pallet_id}
					tick_time={scheme?.tick_time}
				/>
			</DataItemWrapper>
			<DataItemWrapper
				blocksCount={equipment?.weigher ? 1 : 2}
				divider
				label={`${t("panel.titles.defects")}, ${t("measureUnits.pcs")}`}
			>
				<DataItemInfo
					infoBlocks={[
						{
							label: t("panel.titles.heights"),
							info: scheme?.heights_error_count,
							status: scheme?.heights_status,
						},
						{
							label: t("panel.titles.face"),
							info: scheme?.defects_error_count,
							status: scheme?.defects_status,
						},
					]}
				/>
			</DataItemWrapper>
			<DataItemWrapper
				blocksCount={1}
				divider
				label={t("panel.titles.palletInfo")}
			>
				<DataItemInfo
					infoBlocks={[
						{
							label: (
								<WrapWrapper>
									<NoWrapWrapper>
										{t("panel.titles.avg")},
									</NoWrapWrapper>{" "}
									<NoWrapWrapper>
										{t("measureUnits.mm")}
									</NoWrapWrapper>
								</WrapWrapper>
							),
							info: scheme?.avg_height
								? numberFormatter(
										scheme?.avg_height?.toFixed(1)
								  )
								: scheme?.avg_height,
							status: scheme?.avg_overlimit_status,
						},
						...(equipment?.weigher
							? [
									{
										label: (
											<WrapWrapper>
												<NoWrapWrapper>
													{t("panel.titles.density")},
												</NoWrapWrapper>{" "}
												<NoWrapWrapper>
													{system?.density_measure_unit ===
													"kg_dm3"
														? t(
																"measureUnits.kg/dm"
														  )
														: t(
																"measureUnits.kg/m"
														  )}
												</NoWrapWrapper>
											</WrapWrapper>
										),
										info:
											scheme?.density === null ||
											scheme?.density === undefined
												? scheme?.density
												: numberFormatter(
														system?.density_measure_unit ===
															"kg_dm3"
															? (
																	scheme?.density /
																	1000
															  )?.toFixed(2)
															: scheme?.density
												  ),
										status: scheme?.density_overlimit_status,
									},
							  ]
							: []),
					]}
				/>
			</DataItemWrapper>
			{equipment?.weigher ? (
				<DataItemWrapper
					maxWidth={"136px"}
					divider
					label={`${t("panel.titles.weight")}, ${t(
						"measureUnits.kg"
					)}`}
				>
					<WeightInfo
						weight={scheme?.weight}
						pallet_weight={scheme?.pallet_weight}
					/>
				</DataItemWrapper>
			) : null}
			<DataItemWrapper
				maxWidth={equipment?.weigher ? "144px" : "180px"}
				label={t("panel.titles.repeat")}
			>
				<RepeatsVisualizer
					repetitions_grid={scheme?.repetitions_grid}
				/>
			</DataItemWrapper>
		</Content>
	);
};

import { useTranslation } from "react-i18next";
import { ContinueCheckButton } from "./ContinueCheckButton";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	gap: 8px;
	
`;

export const ThresholdResults = ({
	value,
	uuid,
	n_pallets_all,
	n_pallets_checked,
	creation_dt,
	userUuid,
	setLoadingUuid,
	loadingUuid,
}) => {
	const { t } = useTranslation();

	return value ? (
		<div>
			<p>{t("defectInspection.recommendedSettings")}</p>
			<Container>
				<Container>
					<p>{t("color.crack")}:</p>
					{value.crack_best_threshold ?? "-"}
				</Container>
				<Container>
					<p>{t("color.pit")}:</p>
					{value.pit_best_threshold ?? "-"}
				</Container>
				<Container>
					<p>{t("color.stain")}:</p>
					{value.stain_best_threshold ?? "-"}
				</Container>
			</Container>
		</div>
	) : (
		<ContinueCheckButton
			userUuid={userUuid}
			uuid={uuid}
			n_pallets_all={n_pallets_all}
			n_pallets_checked={n_pallets_checked}
			creation_dt={creation_dt}
			setLoadingUuid={setLoadingUuid}
			loadingUuid={loadingUuid}
		/>
	);
};

import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
	BoxPlotController,
	BoxAndWiskers,
} from "@sgratzl/chartjs-chart-boxplot";
import styled from "styled-components";
import { useSelector } from "react-redux";
import DefectsTitle from "./DefectsTitle";
import DefectsList from "./DefectsList";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DensityChart from "./DensityChart";
import { createPluginWithSetLimits } from "../helper/overlimitPointerPlugin";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	BoxPlotController,
	BoxAndWiskers,
	PointElement,
	Title,
	Tooltip,
	Legend
);

const TactNumberWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: ${(props) => (props.isDrySide ? "flex-end" : "flex-start")};
`;

const TactNumber = styled.p`
	width: 20px;
	text-align: center;
`;

const DefectWrapper = styled.div`
	font-size: 14px;
	position: relative;
`;

const EquipAbsence = styled.div`
	aspect-ratio: 9.5/3;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DefectsChart = ({ chart, setPalletUUid, isDrySide }) => {
	const { equipment } = useSelector((state) => state.settings);
	// let { chartGlobal } = useSelector((state) => state.clicked);
	const { t } = useTranslation();
	const processedChart = structuredClone(chart);

	if (isDrySide) processedChart?.history?.reverse();

	const schemeChart = processedChart?.history?.slice(0, 30);

	if (!schemeChart) return <></>;

	const minY = processedChart?.height_min;
	const maxY = processedChart?.height_max;

	const sortedArr = [...schemeChart].reverse().map((item) => {
		const arr = [];
		const sectorsHeight = item.heights;

		if (item.content != 0) {
			arr.push(0);
			return arr;
		}

		const minHeight = Math.min(...sectorsHeight).toFixed(1);

		arr.push(minHeight);

		const midHeight = (
			sectorsHeight?.reduce((prev, cur) => prev + cur, 0) /
			sectorsHeight.length
		).toFixed(1);

		arr.push(midHeight);

		const maxHeight = Math.max(...sectorsHeight).toFixed(1);

		arr.push(maxHeight);

		return arr;
	});

	const minOfHeights = [...schemeChart]
		.reverse()
		.map((item) => {
			const sectorsHeight = item.heights;
			if (item.content != 0) return 0;
			return Math.min(...sectorsHeight).toFixed(1);
		})
		.filter((val) => val !== 0);

	const dottedMaxLine = {
		id: "dottedMaxLine",
		beforeDatasetsDraw(chart, args, options) {
			const {
				ctx,
				chartArea: { top, rigth, bottom, left, width, height },
				scales: { x, y },
			} = chart;
			ctx.save();

			ctx.strokeStyle = "red";
			ctx.setLineDash([5, 5]);
			ctx.strokeRect(left, y.getPixelForValue(maxY), width, 0);
			ctx.restore();
		},
	};

	const dottedMinLine = {
		id: "dottedMinLine",
		beforeDatasetsDraw(chart, args, options) {
			const {
				ctx,
				chartArea: { top, rigth, bottom, left, width, height },
				scales: { x, y },
			} = chart;
			ctx.save();

			ctx.strokeStyle = "blue";
			ctx.setLineDash([5, 5]);
			ctx.strokeRect(left, y.getPixelForValue(minY), width, 0);
			ctx.restore();
		},
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: false,
		plugins: {
			legend: {
				display: false,
				position: "top",
			},
			title: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		scales: {
			y: {
				min: minY - 8,
				max: maxY + 8,
				afterFit: function (scaleInstance) {
					scaleInstance.width = 55;
				},
			},
			x: {
				position: "right",
				ticks: {
					callback: function (value, index, values) {
						return "";
					},
				},
			},
		},
	};

	if (!sortedArr) return <></>;

	const mockLabels = [...Array(30).keys()];

	const labels = mockLabels
		.map((label) => {
			if (label === 0) return label;
			return label * -1;
		})
		.reverse();

	const foolArrData = [...Array(sortedArr.length)]
		.map((_) =>
			Array(2).fill(
				processedChart
					? processedChart.height_min - 1
					: Math.min(...minOfHeights) - 1
			)
		)
		.map((item) => [item[0], item[1] - 2]);

	const overlimitPointer = createPluginWithSetLimits(
		minY - 8,
		maxY + 8,
		[...schemeChart].reverse().map((item) => item.content === 0)
	);

	const data = {
		labels,
		datasets: [
			{
				type: "boxplot",
				label: "Высоты",
				data: [...new Array(30 - sortedArr.length), ...sortedArr],
				backgroundColor: [
					...new Array(30 - schemeChart.length).fill("transparent"),
					...[...schemeChart]
						.reverse()
						.map((item, index) =>
							item.heights_status === "restricted"
								? "#FF3F3F"
								: item.heights_status === "allowed"
								? "#D9DD12"
								: "rgb(12, 156, 35)"
						),
				],
				borderSkipped: false,
				borderWidth: 2.5,
				borderColor: [
					...new Array(30 - schemeChart.length).fill("transparent"),
					...[...schemeChart]
						.reverse()
						.map((item, index) =>
							item.heights_status === "restricted"
								? "#FF3F3F"
								: item.heights_status === "allowed"
								? "#D9DD12"
								: "rgb(12, 156, 35)"
						),
				],
			},
			{
				type: "bar",
				label: "Высоты",
				data: [...new Array(30 - foolArrData.length), ...foolArrData],
				backgroundColor: [
					...new Array(30 - schemeChart.length).fill("transparent"),
					...[...schemeChart]
						.reverse()
						.map((item, index) =>
							item.content === 2 ? "#868686" : "transparent"
						),
				],
				borderSkipped: false,
				borderWidth: 2,
				borderColor: [
					...new Array(30 - schemeChart.length).fill("transparent"),
					...[...schemeChart]
						.reverse()
						.map((item, index) =>
							item.content !== 0 ? "#868686" : "transparent"
						),
				],
			},
		],
	};

	return (
		<DefectWrapper>
			<DefectsTitle isDrySide={isDrySide} />
			<DefectsList
				isDrySide={isDrySide}
				processedChart={processedChart}
				setPalletUUid={setPalletUUid}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-end",
					mt: "1rem",
					ml: "0.7rem",
				}}
			>
				<Box
					sx={{
						aspectRatio: "9.5/3",
						width: "100%",
						position: "relative",
					}}
				>
					<Chart
						type={"bar"}
						redraw={true}
						options={options}
						plugins={[
							dottedMaxLine,
							dottedMinLine,
							overlimitPointer,
						]}
						data={data}
					/>
				</Box>
				{equipment.weigher ? (
					<DensityChart processedChart={processedChart} />
				) : (
					<EquipAbsence>{t("panel.density.fool")}</EquipAbsence>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							width: "calc(45px + 2.1%);",
							fontWeight: 600,
						}}
					>
						{t("panel.prevTacts.tacts")}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: isDrySide ? "row-reverse" : "row",
							width: "100%",
						}}
					>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "30"}
							</TactNumber>
						</TactNumberWrapper>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "25"}
							</TactNumber>
						</TactNumberWrapper>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "20"}
							</TactNumber>
						</TactNumberWrapper>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "15"}
							</TactNumber>
						</TactNumberWrapper>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "10"}
							</TactNumber>
						</TactNumberWrapper>
						<TactNumberWrapper isDrySide={isDrySide}>
							<TactNumber>
								{(isDrySide ? "" : "-") + "5"}
							</TactNumber>
						</TactNumberWrapper>
					</Box>
				</Box>
			</Box>
		</DefectWrapper>
	);
};

export default DefectsChart;

import { Box, Button, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import DefaultSnackbar from "../components/profile/Snackbar";
import useAuth from "../hooks/useAuth";
import { ReactComponent as MainLogo } from "../icons/mainLogo.svg";
import LoginVector from "../images/loginVector.svg";
import { authLogin } from "../store/authSlice";
import { getNoTokenUsers } from "../store/userSlice";
import Support from "./Support";

const Login = () => {
	const authState = useAuth();
	const { t } = useTranslation();
	const [snackState, setSnackState] = useState({
		open: false,
		error: false,
		msg: t("wrongPwd"),
	});
	const navigate = useNavigate();
	const { noTokenUsers } = useSelector((state) => state.user);
	const { error } = useSelector((state) => state.auth);

	useEffect(() => {
		if (error === "Unauthorized")
			setSnackState({
				open: true,
				error: false,
				msg: t("wrongPwd"),
			});
	}, [error]);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getNoTokenUsers());
	}, []);

	useEffect(() => {
		if (authState.token) {
			navigate("/panel");
		}
	}, [authState.token]);

	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => {
		dispatch(
			authLogin({ username: data.username, password: data.password })
		);
	};

	console.log("noTokenUsers", noTokenUsers);

	return (
		<Box
			width="100%"
			height="100vh"
			sx={{
				backgroundColor: "rgb(115, 136, 211)",
				backgroundImage: `url(${LoginVector})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "bottom",
			}}
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
		>
			<Box padding="1rem">
				<MainLogo />
			</Box>
			<Box display={"flex"} justifyContent="center" alignItems="center">
				<Box
					component="form"
					onSubmit={handleSubmit(onSubmit)}
					noValidate
					sx={{ mt: 0, ml: 3, mr: 3, width: "500px" }}
				>
					<TextField
						sx={{
							mb: 5,
							backgroundColor: "#EAFFFA",
							borderRadius: "4px",
						}}
						margin="normal"
						required
						variant="filled"
						fullWidth
						id="username"
						label={t("username")}
						autoComplete="username"
						autoFocus
						select
						{...register("username", {
							required: true,
						})}
					>
						{noTokenUsers?.map((option) => (
							<MenuItem key={option.login} value={option.login}>
								{option.login}
							</MenuItem>
						))}
					</TextField>
					<TextField
						sx={{
							mb: 5,
							backgroundColor: "#EAFFFA",
							borderRadius: "4px",
						}}
						margin="normal"
						required
						variant="filled"
						fullWidth
						id="password"
						type="password"
						label={t("password")}
						autoComplete="password"
						{...register("password", {
							required: true,
						})}
					/>
					<Button
						size="large"
						type="submit"
						fullWidth
						variant="outlined"
						sx={{
							mt: 3,
							color: "black",
							height: 56,
							backgroundColor: "#7CC6B6",
							borderRadius: "12px",
						}}
					>
						{t("log_in")}
					</Button>
				</Box>
			</Box>
			<DefaultSnackbar
				open={snackState.open}
				setOpen={setSnackState}
				error={snackState.error}
				msg={snackState.msg}
			/>
		</Box>
	);
};

export default Login;

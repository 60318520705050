import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button } from "@mui/material";
import {
	getNextPallet,
	setCounter,
	setSessionId,
	setTestDate,
	setTestLimit,
} from "../store/defectsInspectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDateFormatter } from "../hooks/useDateFormatter";
import CircleLoader from "./UI/CircleLoader";

export const ContinueCheckButton = ({
	uuid,
	n_pallets_all,
	n_pallets_checked,
	creation_dt,
	userUuid,
	setLoadingUuid,
	loadingUuid,
}) => {
	const dispatch = useDispatch();
	const dateFormatter = useDateFormatter();

	const { t } = useTranslation();

	const { users } = useSelector((state) => state.user);

	const handleClick = () => {
		setLoadingUuid(uuid);
		dispatch(setTestLimit(n_pallets_all));
		dispatch(setSessionId(uuid));
		dispatch(setCounter(n_pallets_checked));
		dispatch(setTestDate(dateFormatter(creation_dt)));
		dispatch(getNextPallet({ sessionId: uuid }));
	};

	return n_pallets_all !== n_pallets_checked ? (
		<Button
			sx={{ display: "flex", alignItems: "center", gap: "8px" }}
			disabled={userUuid !== users.id || loadingUuid}
			onClick={handleClick}
		>
			<ArrowRightIcon /> {t("defectInspection.continue")}{" "}
			{loadingUuid === uuid ? <CircleLoader /> : null}
		</Button>
	) : null;
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColorPreview } from "../../store/colorSlice";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CircleLoader from "../UI/CircleLoader";

const Image = styled.img`
	width: 280px;
	aspect-ratio: 14/11;
`;

const PlaceHolder = styled.div`
	width: 280px;
	aspect-ratio: 14/11;
	border: 1px solid #ccc;
	display: flex;
	justify-content: center;
	font-size: 24px;
	align-items: center;
`;

const ImagePreview = ({ color_id }) => {
	const { colorPreview } = useSelector((state) => state.color);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const filterById = colorPreview.filter((item) => item.id === color_id);
	const [isLoading, setIsLoading] = useState(Boolean(!filterById[0]?.blob));
	const observerTarget = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (
					!filterById[0]?.blob &&
					entries[0].isIntersecting &&
					isLoading
				)
					dispatch(getColorPreview(color_id)).then(() =>
						setIsLoading(false)
					);
			},
			{ threshold: 0.1 }
		);

		if (observerTarget.current) {
			observer.observe(observerTarget.current);
		}

		return () => {
			if (observerTarget.current) {
				observer.unobserve(observerTarget.current);
			}
		};
	}, [isLoading]);

	return { ...filterById }[0]?.blob ? (
		<Image src={{ ...filterById }[0]?.blob} loading="lazy" />
	) : (
		<PlaceHolder ref={observerTarget}>
			{isLoading ? <CircleLoader /> : t("noExamples")}
		</PlaceHolder>
	);
};

export default ImagePreview;

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPalletsFromBatch, getPalletInfo } from "../store/newReportSlice";
import { useTranslation } from "react-i18next";
import { TactPopup } from "./TactPopup";
import { TabsComponent } from "./TabsComponent";
import { ReportPalletScreen } from "./ReportPalletScreen";
import { TactCharts } from "./TactCharts";
import { useRef } from "react";
import { valueExtractor } from "../helper/valueExtractor";

const Content = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border-radius: 6px;
	padding: 1rem;
`;

export const DataVisualization = ({
	refreshWithFilters,
	resetFilters,
	filters,
	setFilters,
}) => {
	const {
		palletsForBatch,
		palletsStatus,
		palletImages,
		filteredPallets,
		selectedBatch,
	} = useSelector((state) => state.newReport);
	const isUpdate = useRef(false);

	const [selectedPalletIndex, setSelectedPalletIndex] = useState(0);
	const [isFilteredOnly, setIsFilteredOnly] = useState(false);

	const { t } = useTranslation();

	const dispatch = useDispatch();

	useEffect(() => {
		if (isUpdate.current) {
			isUpdate.current = false;
		} else {
			setSelectedPalletIndex(0);
		}
	}, [palletsForBatch]);

	const handleTactTimelineClick = (tact) => {
		setSelectedPalletIndex(
			(isFilteredOnly
				? filteredPallets
				: palletsForBatch.map((pallet) => pallet.uuid)
			).findIndex((pallet) => tact.uuid === pallet)
		);
	};

	const updateAfterTagApply = () => {
		isUpdate.current = true;
		return dispatch(
			getAllPalletsFromBatch({
				batch: selectedBatch,
				filters: valueExtractor(filters),
			})
		);
	};

	return selectedBatch ? (
		<Content>
			{palletsForBatch?.length &&
			(palletsStatus !== "pending" || isUpdate.current) ? (
				<TabsComponent
					tabs={[
						{
							label: t("report.opScreen"),
							content: (
								<ReportPalletScreen
									Popup={TactPopup}
									handleClick={handleTactTimelineClick}
									isLoaded={
										palletsStatus === "fulfilled" ||
										isUpdate.current
									}
									apply={refreshWithFilters}
									reset={resetFilters}
									filters={filters}
									setFilters={setFilters}
									setSelectedPalletIndex={
										setSelectedPalletIndex
									}
									updateAfterTagApply={updateAfterTagApply}
									selectedPalletIndex={selectedPalletIndex}
									pallets={palletsForBatch}
									filteredPallets={filteredPallets}
									palletImages={palletImages}
									isFilteredOnly={isFilteredOnly}
									setIsFilteredOnly={setIsFilteredOnly}
								/>
							),
						},
						{
							label: t("report.charts"),
							content: <TactCharts pallets={palletsForBatch} />,
						},
					]}
				/>
			) : null}
		</Content>
	) : null;
};

const requestWrapper = (onAuthFailure) => async (url, opts) => {
	const options = structuredClone(opts);
	if (localStorage.getItem("apiToken")) {
		options.headers = {
			...options.headers,
			Authorization: "Bearer " + localStorage.getItem("apiToken"),
		};
	}

	try {
		const res = await fetch(url, options);
		if (!res.ok) {
			if (res.status === 422) {
				throw Error(
					JSON.stringify({
						status: String(res.status),
						body: await res.json(),
					})
				);
			} else {
				throw Error(JSON.stringify({ status: String(res.status) }));
			}
		}
		let result;
		if (res.headers.get("content-length") === "0") return;
		switch (options.headers["Content-Type"]) {
			case "image/webp":
				result = URL.createObjectURL(await res.blob());
				break;
			case "application/json":
				result = await res.json();
				break;
			case "application/octet-stream":
				result = {
					url: URL.createObjectURL(
						new Blob([await res.blob()], { type: "text/html" })
					),
					fileName:
						res.headers
							.get("Content-Disposition")
							.indexOf("filename=") > -1
							? res.headers
									.get("Content-Disposition")
									.split("filename=")[1]
									.slice(1, -1)
							: "file",
				};
				break;
			default:
				break;
		}
		return result;
	} catch (err) {
		if (err instanceof Error)
			if (JSON.parse(err.message).status === "401") {
				onAuthFailure();
				return;
			}
		throw new Error(err.message);
	}
};

export default requestWrapper;

import requestWrapper from "../../helper/requestWrapper";
import { store } from "../../store";
import { authLogout } from "../../store/authSlice";

export async function getZippedImageByPallet(ref) {
	return await requestWrapper(() => {
		store.dispatch(authLogout());
	})(`/api/pallets/${ref.pallet_id}/image?zipped=true`, {
		method: "GET",
		headers: {
			"Content-Type": "image/webp",
		},
	});
}

export async function getZippedImageByLayoutId(ref) {
	return await requestWrapper(() => {
		store.dispatch(authLogout());
	})(`/api/products/examples/${ref.ref}?zipped=true`, {
		method: "GET",
		headers: {
			"Content-Type": "image/webp",
		},
	});
}

export async function addLayout(layout) {
	return await requestWrapper(() => {
		store.dispatch(authLogout());
	})(`/api/products`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(layout),
	});
}

export async function deleteLayoutById(layout_id) {
	try {
		return await requestWrapper(() => {
			store.dispatch(authLogout());
		})(`/api/products/${layout_id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (error) {
		throw new Error("Server error");
	}
}

export async function getExamplesByLayoutId(layout_id) {
	try {
		return await requestWrapper(() => {
			store.dispatch(authLogout());
		})(`/api/products/${layout_id}/images`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (error) {
		throw new Error("Server error");
	}
}

export async function getProductById(layout_id) {
	try {
		return await requestWrapper(() => {
			store.dispatch(authLogout());
		})(`/api/products/${layout_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (error) {
		throw new Error("Server error");
	}
}

export async function getLayoutPreviewByLayoutId(layout_id) {
	try {
		return await requestWrapper(() => {
			store.dispatch(authLogout());
		})(`/api/products/${layout_id}/preview`, {
			method: "GET",
			headers: {
				"Content-Type": "image/webp",
			},
		});
	} catch (error) {
		throw new Error("Server error");
	}
}

export const updateLayoutById = async (layout_id, layout) => {
	return await requestWrapper(() => {
		store.dispatch(authLogout());
	})(`/api/products/${layout_id}`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(layout),
	});
};

export const addExampleByLayoutId = async (layout_id, pallet_id) => {
	try {
		await requestWrapper(() => {
			store.dispatch(authLogout());
		})(
			`/api/products/${layout_id}/images?pallet_id=${encodeURIComponent(
				pallet_id
			)}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
	} catch (error) {
		throw new Error("Server error");
	}
};

export const deleteExampleByLayoutId = async (image_ref) => {
	try {
		await requestWrapper(() => {
			store.dispatch(authLogout());
		})(`/api/products/examples/${image_ref}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (error) {
		throw new Error("Server error");
	}
};

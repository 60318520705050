import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setDateFormat } from "../../store/settingsSlice";

const SwitchLangToggle = () => {
	const { dateFormat } = useSelector((state) => state.settings);
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();

	const handleChange = (event) => {
		i18n.changeLanguage(event.target.value);
		localStorage.setItem("language", event.target.value);
	};

	return (
		<Box sx={{ display: "flex", gap: "1rem" }}>
			<Box display={"flex"} alignItems={"center"} gap={"15px"}>
				<Typography>{t("settings.title.lang")}:</Typography>
				<Select value={i18n.language} onChange={handleChange}>
					<MenuItem value={"ru"}>RU</MenuItem>
					<MenuItem value={"en"}>EN</MenuItem>
					<MenuItem value={"de"}>DE</MenuItem>
				</Select>
			</Box>
			<Box display={"flex"} alignItems={"center"} gap={"15px"}>
				<Typography>{t("settings.title.dateFormat")}:</Typography>
				<Select
					value={dateFormat}
					onChange={(event) =>
						dispatch(setDateFormat(event.target.value))
					}
				>
					<MenuItem value={"DD/MM/YYYY"}>dd/mm/yyyy</MenuItem>
					<MenuItem value={"YYYY/MM/DD"}>yyyy/mm/dd</MenuItem>
					<MenuItem value={"MM/DD/YYYY"}>mm/dd/yyyy</MenuItem>
				</Select>
			</Box>
		</Box>
	);
};

export default SwitchLangToggle;

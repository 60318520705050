import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useDateFormatter } from "../hooks/useDateFormatter";

const Text = styled.p`
	display: flex;
	gap: 0.5rem;
`;

const Value = styled.p`
	font-weight: 600;
`;

export const BatchPopup = ({ product_name, color_name, total, bdt, edt }) => {
	const { t } = useTranslation();
	const dateFormatter = useDateFormatter();

	return (
		<div>
			<div>
				<Text>
					{t(`bdt`)}:
					<Value>
						{dateFormatter(bdt, true) + " " + bdt?.slice(11, 16)}
					</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`edt`)}:
					<Value>
						{dateFormatter(edt, true) + " " + edt?.slice(11, 16)}
					</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`panel.dataList.form`)}
					<Value>{product_name}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`panel.dataList.color`)}
					<Value>{color_name}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`report.table.rfidAll`)}:<Value>{total}</Value>
				</Text>
			</div>
		</div>
	);
};

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../store/userSlice";
import RoleSelect from "./RoleSelect";
import UserField from "./UserField";

const ChangeUser = ({ users, userId }) => {
	const [userParams, setUserParams] = useState({
		name: users.name,
		role: users.role,
	});
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const clickHandler = () => {
		dispatch(
			updateUserData({
				user_id: userId,
				name: userParams.name,
				role: userParams.role,
			})
		);
	};

	if (!users) return <></>;

	return (
		<Box
			width="30vw"
			height="34vh"
			display={"flex"}
			alignItems="center"
			flexDirection="column"
			sx={{
				backgroundColor: "white",
				borderRadius: "6px",
				boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
			}}
		>
			<Typography sx={{ fontSize: "22px", fontWeight: "600", m: "10px" }}>
				{t("profile.user_label")}
			</Typography>
			<Box
				display={"flex"}
				flexDirection="row"
				justifyContent={"space-around"}
				width="100%"
			>
				<Box display={"flex"} flexDirection="column" gap="2rem">
					<Typography>{t("profile.user_name")}</Typography>
					<UserField
						userName={userParams.name}
						userParams={userParams}
						setUserName={setUserParams}
					/>
				</Box>
			</Box>
			<Typography m="2rem" fontSize={"14px"}>
				{t("profile.user_desc")}
			</Typography>
			<Button onClick={clickHandler}>{t("profile.apply")}</Button>
		</Box>
	);
};

export default ChangeUser;

import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import CurrentBatch from "./CurrentBatch";
import DefectsChart from "./DefectsChart";
import { useMediaQuery } from "../hooks/useMediaQuery";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useTranslation } from "react-i18next";
import CircleLoader from "./UI/CircleLoader";

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	width: ${(props) => (props.isSmall ? "calc(100vw - 8rem)" : "38vw")};
`;

const ChartWrapper = styled.div`
	background-color: white;
	border-radius: 6px;
	padding: 1rem 12px;
	z-index: 1;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const InfoList = ({
	loaded,
	handleChartClick,
	batch,
	chart,
	angle,
	handleRotation,
	handlePointCloudClick,
	cloudStatus,
}) => {
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const { t } = useTranslation();

	return (
		<ListWrapper isSmall={isSmall || isPortrait}>
			<CurrentBatch batch={batch} loaded={loaded} />
			<ChartWrapper>
				<DefectsChart
					setPalletUUid={handleChartClick}
					chart={chart}
					loaded={loaded}
				/>
			</ChartWrapper>
			<ButtonWrapper>
				<Button
					sx={{ height: "44px", display: "flex", gap: "8px" }}
					onClick={handleRotation}
				>
					{t("dry.btn")}
					<ArrowUpwardIcon
						sx={{
							transform: `rotate(${180 + angle}deg) scale(0.9)`,
						}}
					/>
				</Button>
				<Button
					sx={{ height: "44px" }}
					disabled={cloudStatus === "pending"}
					onClick={handlePointCloudClick}
				>
					{cloudStatus === "pending" ? (
						<CircleLoader />
					) : (
						t("pointCloud")
					)}
				</Button>
			</ButtonWrapper>
		</ListWrapper>
	);
};

export default InfoList;
